import React from 'react';
import { FiInstagram } from 'react-icons/fi';
import styled from 'styled-components';

const List = styled.ul`
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
      margin-left: 10px;
    }
  }
`;


const SocialList = () => (
  <List>
    <li>
      <FiInstagram size="1.5em" />
      <a target="__blank" href="https://www.instagram.com/gngn.nl/">
        @gngn.nl
      </a>
    </li>
  </List>
);

export default SocialList;
