import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Logo, SocialList } from 'components';
import styled, { createGlobalStyle } from 'styled-components';
import 'bootstrap/scss/bootstrap-reboot.scss';
import 'bootstrap/scss/bootstrap-grid.scss';

console.log(process.env.REACT_APP_HT_PASS);

const Styles = createGlobalStyle`
  html body {
    color: #313131;
    font-family: anonymous-pro, monospace;
  }

  ${() => {
    let headers = '';

    for (let i = 1; i <= 5; i += 1) {
      headers += `h${i} { font-family: 'Poppins', sans-serif; }`;
    }

    return headers;
  }}
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  > p {
    text-align: center;
    margin-top: 20px;
  }
`;

const App = () => (
  <>
    <Wrapper className="container">
      <Logo height={80} animatedGradient />
      <p>
          Hier word hard gewerkt aan de nieuwe website van gngn.
        <br />
          Volg ons op socialmedia om op de hoogte te blijven!
      </p>
      <SocialList />
    </Wrapper>
    <Styles />
  </>
);

ReactDOM.render(<App />, document.getElementById('root'));
