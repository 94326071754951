import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSpring, animated, config } from 'react-spring';
import PropTypes from 'prop-types';


const AnimatedBadge = ({ logoHeight }) => {
  const gradients = [
    'linear-gradient(135deg, #FFE259, #FFA751)',
    'linear-gradient(135deg, #00DBDE, #DC00FF)',
    'linear-gradient(135deg, #EB3349, #F45C43)',
    'linear-gradient(135deg, #3CD3AD, #4CB8C4)',
    'linear-gradient(135deg, #5FC3E4, #E55D87)',
    'linear-gradient(135deg, #8360c3, #2ebf91)',
    'linear-gradient(135deg, #FFE259, #FFA751)',
  ];

  const [didMount, setDidMount] = useState(false);
  const [curGradient, setCurGradient] = useState(0);


  useEffect(() => setDidMount(true), []);

  const nextGradient = (curGradient + 1) >= gradients.length ? 0 : (curGradient + 1);


  const props = useSpring({
    to: { background: gradients[didMount ? nextGradient : curGradient] },
    from: { background: gradients[curGradient] },
    config: { ...config.molasses, duration: 1000 },
    onRest: () => setTimeout(() => setCurGradient(nextGradient), 1000),
  });

  const Animated = animated(Badge);

  return (
    <Animated style={props} logoHeight={logoHeight} />
  );
};

const Logo = ({ height, animatedGradient, badgeOnly }) => (
  <LogoWrapper>
    <BadgeWrapper logoHeight={height}>
      {
        !animatedGradient ? <Badge logoHeight={height} />
          : <AnimatedBadge logoHeight={height} />
      }
      <BadgeText logoHeight={height} />
    </BadgeWrapper>
    {
      !badgeOnly && (
        <TextWrapper logoHeight={height}>
          <h1>gngn.nl</h1>
          <p>web & software development.</p>
        </TextWrapper>
      )
    }
  </LogoWrapper>
);

AnimatedBadge.propTypes = {
  logoHeight: PropTypes.number.isRequired,
};

Logo.propTypes = {
  height: PropTypes.number,
  badgeOnly: PropTypes.bool,
  animatedGradient: PropTypes.bool,
};

Logo.defaultProps = {
  height: 80,
  badgeOnly: false,
  animatedGradient: false,
};

const LogoWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const BadgeWrapper = styled.div`
  width: ${({ logoHeight }) => logoHeight}px;
  height: ${({ logoHeight }) => logoHeight}px;
  overflow: hidden;
  position: relative;
  border-radius: ${({ logoHeight }) => logoHeight * 0.15}px;
`;

const Badge = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #FFE259, #FFA751);
`;

const BadgeText = styled.span`
  top: 50%;
  left: 50%;
  color: #FFF;
  position: absolute;
  transform: translate(-50%, -55%);
  font-size: ${({ logoHeight }) => logoHeight * 0.535}px;
  font-family: 'Poppins', sans-serif;

  &:before{
    content: "gn.";
    z-index: 2;
    position: relative;
  }

  &:after {
    top: 1px;
    left: 1px;
    z-index: 1;
    opacity: .2;
    content: 'gn.';
    position: absolute;
    color: #000;
    text-shadow: ${({ logoHeight }) => {
    let str = '';
    for (let i = 1; i <= logoHeight; i += 1) {
      str += `,${i * 0.5}px ${i * 0.5}px rgb(0, 0, 0)`;
    }
    return str.substr(1);
  }}
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  margin-left: 10px;

  h1 {
    top: 50%;
    left: 0;
    margin: 0;
    position: absolute;
    transform: translateY(-59%);
    font-size: ${({ logoHeight }) => logoHeight * 0.535}px;
  }
  p {
    margin: 0;
    font-size: ${({ logoHeight }) => logoHeight * 0.1625}px;
  }
`;

export default Logo;
